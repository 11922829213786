import cx from 'classnames';
import { History } from 'history';
import {
    index as categoriesIndex,
    prams_and_accessories as pramsAndAccessoriesCategory,
} from 'mk/autogenerated/bzCategories';
import {
    BZ2_CAT_FOOTWEAR,
    BZ2_CAT_PRAMS,
    BZ2_CAT_TOYS,
    HOME_SHOW_count_PRODUCTS_FROM_location,
    HOME_SHOW_PRODUCTS_FROM_YOUR_LOCATION,
    HOMEPAGE_MORE_CATEGORIES,
} from 'mk/autogenerated/translations/Bazaar.c146af455938618c064047f296ef02cc'
import { formatThousands } from 'mk/bazaar/common/formatters';
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { getCountyName } from 'mk/geo';
import { bazaar_single_page_product_grid_category_url } from 'mk/urls/functions';
import { bazaarUrl } from 'mk2/apps/bazaar/urls';
import BazaarSearch from 'mk2/apps/home/components/BazaarSearch';
import { HomePageState } from 'mk2/apps/home/containers/Home/Home.reducers';
import { homepageUrl } from 'mk2/apps/home/urls';
import { settingsLocationUrl } from 'mk2/apps/settings/urls';
import { signupUrl } from 'mk2/apps/users/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { url } from 'mk2/helpers/urls';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './Bazaar.mscss';

const pramsCategory = pramsAndAccessoriesCategory.find((c) => c.name === 'prams');
const footwearCategory = categoriesIndex.find((c) => c.name === 'footwear');
const toysCategory = categoriesIndex.find((c) => c.name === 'toys');

interface OwnProps {
    className?: string;

    prams: number;
    footwear: number;
    toys: number;
    others: number;
    history?: History;
    isMobile: boolean;
    places: Record<number, number>;
    place: HomePageState['place'];
    requestUser: UserEntity;
}

type Props = OwnProps;

class Bazaar extends React.PureComponent<Props> {
    public render() {
        const { className, isMobile, toys, footwear, prams, others, place, places, requestUser, history } = this.props;

        return (
            <div className={cx(styles.Bazaar, className)}>
                <BazaarSearch history={history} />

                <div className={styles.Bazaar__categories}>
                    <Link
                        className={styles.Bazaar__category}
                        to={bazaar_single_page_product_grid_category_url(pramsCategory.slug)}
                    >
                        <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--prams'])} />
                        <div className={styles.Bazaar__category__info}>
                            <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_PRAMS}</span>
                            <span className={styles.Bazaar__category__info__hits}>{formatThousands(prams)}</span>
                        </div>
                    </Link>
                    <Link
                        className={styles.Bazaar__category}
                        to={bazaar_single_page_product_grid_category_url(footwearCategory.slug)}
                    >
                        <div
                            className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--footwear'])}
                        />
                        <div className={styles.Bazaar__category__info}>
                            <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_FOOTWEAR}</span>
                            <span className={styles.Bazaar__category__info__hits}>{formatThousands(footwear)}</span>
                        </div>
                    </Link>
                    <Link
                        className={styles.Bazaar__category}
                        to={bazaar_single_page_product_grid_category_url(toysCategory.slug)}
                    >
                        <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--toys'])} />
                        <div className={styles.Bazaar__category__info}>
                            <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_TOYS}</span>
                            <span className={styles.Bazaar__category__info__hits}>{formatThousands(toys)}</span>
                        </div>
                    </Link>
                    <Link
                        className={styles.Bazaar__category}
                        to={url(bazaarUrl, {}, isMobile ? { opened: 'cats' } : {})}
                    >
                        <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--others'])} />
                        <div className={styles.Bazaar__category__info}>
                            <span className={styles.Bazaar__category__info__title}>{HOMEPAGE_MORE_CATEGORIES}</span>
                            <span className={styles.Bazaar__category__info__hits}>{formatThousands(others)}</span>
                        </div>
                    </Link>
                </div>

                <div className={styles.Bazaar__placeCount}>
                    {place &&
                    places[place.id] &&
                    place.countyId && (// Places without countyId cannot be set in facets
                            <Btn
                                type={BtnType.BlueOutline}
                                link={url(bazaarUrl, {}, {
                                    places: place.id,
                                    locations: getCountyName(place.countyId),
                                })}
                                label={
                                    <Interpolate
                                        i18nKey={HOME_SHOW_count_PRODUCTS_FROM_location}
                                        count={places[place.id]}
                                        location={<strong>{place.name}</strong>}
                                    />
                                }
                            />
                        )}
                    {!place && (
                        <Btn
                            link={
                                isAuthenticated(requestUser)
                                    ? url(settingsLocationUrl, { username: requestUser.username })
                                    : url(
                                          signupUrl,
                                          {},
                                          {
                                              next: homepageUrl,
                                          },
                                      )
                            }
                            type={BtnType.BlueOutline}
                            label={HOME_SHOW_PRODUCTS_FROM_YOUR_LOCATION}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default Bazaar;

interface PlaceholderOwnProps {
    className?: string;
    history?: History;
    isMobile: boolean;
}

let BazaarPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, history, isMobile }) => (
    <div className={cx(styles.Bazaar, className)}>
        <BazaarSearch history={history} />

        <div className={styles.Bazaar__categories}>
            <Link
                className={styles.Bazaar__category}
                to={bazaar_single_page_product_grid_category_url(pramsCategory.slug)}
            >
                <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--prams'])} />
                <div className={styles.Bazaar__category__info}>
                    <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_PRAMS}</span>
                    <span className={styles.Bazaar__category__info__hits}>
                        <LoadingPlaceholder width={80} />
                    </span>
                </div>
            </Link>
            <Link
                className={styles.Bazaar__category}
                to={bazaar_single_page_product_grid_category_url(footwearCategory.slug)}
            >
                <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--footwear'])} />
                <div className={styles.Bazaar__category__info}>
                    <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_FOOTWEAR}</span>
                    <span className={styles.Bazaar__category__info__hits}>
                        <LoadingPlaceholder width={80} />
                    </span>
                </div>
            </Link>
            <Link
                className={styles.Bazaar__category}
                to={bazaar_single_page_product_grid_category_url(toysCategory.slug)}
            >
                <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--toys'])} />
                <div className={styles.Bazaar__category__info}>
                    <span className={styles.Bazaar__category__info__title}>{BZ2_CAT_TOYS}</span>
                    <span className={styles.Bazaar__category__info__hits}>
                        <LoadingPlaceholder width={80} />
                    </span>
                </div>
            </Link>
            <Link
                className={styles.Bazaar__category}
                to={url(bazaarUrl, {}, isMobile ? { opened: 'cats' } : {})}
            >
                <div className={cx(styles.Bazaar__category__icon, styles['Bazaar__category__icon--others'])} />
                <div className={styles.Bazaar__category__info}>
                    <span className={styles.Bazaar__category__info__title}>{HOMEPAGE_MORE_CATEGORIES}</span>
                    <span className={styles.Bazaar__category__info__hits}>
                        <LoadingPlaceholder width={80} />
                    </span>
                </div>
            </Link>
        </div>

        <div className={styles.Bazaar__placeCount}>
            <LoadingPlaceholder width={300} height={34} />
        </div>
    </div>
);

BazaarPlaceholder.displayName = 'BazaarPlaceholder';
BazaarPlaceholder = React.memo<PlaceholderOwnProps>(BazaarPlaceholder);

export { BazaarPlaceholder };
