import {
    FORUM_WHERE_IS_DISCUSSED,
    HOME_RIGHT_LATEST_REVIEWS,
    HOME_RIGHT_MORE_REVIEWS,
} from 'mk/autogenerated/translations/HomeRight.062116e5a66d420fdb655a71ac52bd5f'
import { boldize, linebreaksbr, mentionize, upperCaseFirst, urlize } from 'mk/bazaar/common/filters';
import { hasAds, isAuthenticated } from 'mk/bazaar/common/userUtils';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { MK_TESTING_USERNAME } from 'mk2/apps/blogs/constants';
import { BrandDaysPosts } from 'mk2/apps/home/components/BrandDaysPosts';
import ContestPosts from 'mk2/apps/home/components/ContestPosts';
import Counselling, { CounsellingPlaceholder } from 'mk2/apps/home/components/Counselling';
import ForumPosts, { ForumPostsPlaceholder } from 'mk2/apps/home/components/ForumPosts';
import HomeBaseBannerComponent from 'mk2/apps/home/components/HomeBaseBannerComponent';
import Photos, { PhotosPlaceholder } from 'mk2/apps/home/components/Photos';
import Strollers, { StrollersPlaceholder } from 'mk2/apps/home/components/Strollers';
import SubscribedPhotoblogs from 'mk2/apps/home/components/SubscribedPhotoblogs';
import { Props } from 'mk2/apps/home/containers/Home/HomePage';
import { dummyPhoto } from 'mk2/apps/strollers/constants';
import { strollersDetailUrl, strollersReviewsUrl } from 'mk2/apps/strollers/urls';
import { WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { AdformInview } from 'mk2/components/AdformInview';
import { Emojify } from 'mk2/components/Emojify';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { Count } from 'mk2/components/NotificationsBadge';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import { ProductReview } from 'mk2/components/ProductReview';
import { AdSlot } from 'mk2/containers/AdSlot/AdSlot';
import { getAllDescendantsOfTypes, getAttributesDict, removeQuotes } from 'mk2/helpers/article_ast/utils';
import { cacheLast } from 'mk2/helpers/cache';
import { hasVal } from 'mk2/helpers/sanitize';
import { url } from 'mk2/helpers/urls';
import { isSuccess, LoadingState } from 'mk2/reducers/loadingState';
import { ExperienceEntity, PhotoEntity, StrollerPhoto, StrollerUserReviewEntity } from 'mk2/schemas';
import React from 'react';
import styles from './HomeRight.mscss';

interface StrollerProductReview {
    type: 'stroller';
    data: StrollerUserReviewEntity;
    photo: StrollerPhoto;
}

interface ExperienceProductReview {
    type: 'experience';
    data: ExperienceEntity;
    body: React.ReactNode[];
    cover: PhotoEntity;
}

type ProductReviewType = StrollerProductReview | ExperienceProductReview;

export class HomeRight extends React.PureComponent<Props> {
    private reviewsCache = cacheLast(true);

    public render() {
        const {
            brandDaysCampaign,
            brandDaysPosts,
            contestPosts,
            counsellings,
            forumPosts,
            isMobile,
            lastVisitCountFriends,
            loadingState,
            location,
            numStrollers,
            photoblogPosts,
            requestUser,
            strollerReviews,
            reviewsStrollersMap,
            strollerArticles,
            wikiExperiences,
            strollers,
            strollersPhotosMap,
            strollersPreviewPhotoMap,
        } = this.props;

        const showBrandDays = !!brandDaysCampaign && brandDaysPosts.length > 0 && hasAds(requestUser);

        const reviews: ProductReviewType[] = this.reviewsCache(
            () => [
                ...strollerReviews.map(
                    (r): ProductReviewType => ({
                        type: 'stroller',
                        data: r,
                        photo: reviewsStrollersMap[r.strollerId]?.previewPhoto ?? dummyPhoto,
                    }),
                ),
                ...wikiExperiences.map(
                    (r): ProductReviewType => {
                        // prepare experience message body
                        let bodyChunks: React.ReactChild[] = [r.text];
                        bodyChunks = linebreaksbr(upperCaseFirst(mentionize(urlize(bodyChunks, 50))));
                        if (r.authorUser.username === MK_TESTING_USERNAME) {
                            bodyChunks = boldize(bodyChunks);
                        }

                        let cover: PhotoEntity;
                        if (r.article.bodyAST) {
                            const mediaNodes = getAllDescendantsOfTypes(r.article.bodyAST, ['pic']);
                            let code: string;
                            for (const mediaNode of mediaNodes) {
                                const attributes = getAttributesDict(mediaNode);

                                if (!code || attributes.cover) {
                                    // Pick first if not found
                                    code = removeQuotes(attributes.code ?? attributes.cd ?? null);
                                }
                            }

                            if (code) {
                                cover = r.article.photos.find((p) => p.code === code);
                            }
                        }

                        return { type: 'experience', data: r, body: bodyChunks, cover };
                    },
                ),
            ],
            strollerReviews,
            wikiExperiences,
        );

        reviews.sort((a, b) => {
            const aTime = new Date(a.type === 'stroller' ? a.data.createdTime : a.data.created).getTime();
            const bTime = new Date(b.type === 'stroller' ? b.data.createdTime : b.data.created).getTime();
            return bTime - aTime;
        });

        return (
            <div className={styles.HomeRight}>
                {(isSuccess(loadingState) || forumPosts.length > 0) && showBrandDays && (
                    <BrandDaysPosts
                        className={styles.HomeRight__brandDaysPosts}
                        brandDaysCampaign={brandDaysCampaign}
                        brandDaysPosts={brandDaysPosts}
                    />
                )}

                {/* ensure ad is loading during LoadingState.INIT, we want it to display asap */}
                <AdformInview
                    placementId={ACTIVE_SERVER_ID === 201 ? 544773 : 402804}
                    passback={
                        !isMobile ? (
                            <AdSlot
                                zone="Homepage - Rectangle 1 (Desktop)"
                                location={location}
                                marginBottomWithAd={10}
                            />
                        ) : (
                            <AdSlot
                                zone="Homepage - Rectangle 2 (Mobile)"
                                location={location}
                                marginBottomWithAd={10}
                            />
                        )
                    }
                    marginBottomWithAd={10}
                />

                {loadingState === LoadingState.INIT || (loadingState === LoadingState.LOADING && !forumPosts.length) ? (
                    <>
                        <ForumPostsPlaceholder className={styles.HomeRight__forumPosts} />
                        <PhotosPlaceholder className={styles.HomeRight__photos} />
                        <StrollersPlaceholder className={styles.HomeRight__strollers} />
                        <CounsellingPlaceholder className={styles.HomeRight__counselling} />
                    </>
                ) : isSuccess(loadingState) || forumPosts.length ? (
                    <>
                        {!isMobile && isAuthenticated(requestUser) && hasVal(lastVisitCountFriends) && (
                            <SubscribedPhotoblogs
                                className={styles.HomeRight__subscribedPhotoblogs}
                                lastVisitCountFriends={lastVisitCountFriends as Count}
                            />
                        )}
                        {!isMobile &&
                            <HomeBaseBannerComponent
                                className={styles.HomeRight__subscribedPhotoblogs}
                            />
                        }
                        {contestPosts.length > 0 && (
                            <ContestPosts className={styles.HomeRight__contestPosts} posts={contestPosts} />
                        )}
                        <AdSlot
                            zone={
                                isMobile
                                    ? 'Homepage - NativeRectangle 1 (Mobile)'
                                    : 'Homepage - NativeRectangle 1 (Desktop)'
                            }
                            location={location}
                            marginTopWithAd={10}
                            marginBottomWithAd={10}
                        />
                        <ForumPosts
                            heading={FORUM_WHERE_IS_DISCUSSED}
                            posts={forumPosts}
                            className={styles.HomeRight__forumPosts}
                        />
                        <Strollers
                            className={styles.HomeRight__strollers}
                            numStrollers={numStrollers}
                            guideArticles={strollerArticles}
                            strollers={strollers}
                            strollersPhotosMap={strollersPhotosMap}
                            strollersPreviewPhotoMap={strollersPreviewPhotoMap}
                        />
                        <Photos className={styles.HomeRight__photos} posts={photoblogPosts} />
                        {reviews.length > 0 && (
                            <Panel className={styles.HomeRight__reviews}>
                                <PanelHeading
                                    title={HOME_RIGHT_LATEST_REVIEWS}
                                    url={getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG)}
                                />

                                <div className={styles.HomeRight__reviews__reviews}>
                                    {reviews
                                        .slice(0, 2)
                                        .map((r) =>
                                            r.type === 'stroller' ? (
                                                (!!reviewsStrollersMap[r.data.strollerId] && <ProductReview
                                                    key={`stroller-${r.data.id}`}
                                                    className={styles.HomeRight__reviews__reviews__review}
                                                    image={
                                                        <ImgCropped
                                                            width={64}
                                                            height={64}
                                                            mode={ImgCropMode.Embed}
                                                            imgWidth={r.photo.originalWidth}
                                                            imgHeight={r.photo.originalHeight}
                                                            imgUrl={r.photo.url100x100}
                                                        />
                                                    }
                                                    title={
                                                        <Link
                                                            to={url(strollersDetailUrl, { slug: reviewsStrollersMap[r.data.strollerId].slug })}
                                                        >
                                                            {reviewsStrollersMap[r.data.strollerId]?.name}
                                                        </Link>
                                                    }
                                                    user={r.data.user}
                                                    createdAt={r.data.createdTime}
                                                    text={r.data.text}
                                                    photosCount={r.data.photos.length}
                                                    positivesCount={r.data.positives.length}
                                                    negativesCount={r.data.negatives.length}
                                                    score={r.data.score}
                                                    to={url(
                                                        strollersReviewsUrl,
                                                        { slug: reviewsStrollersMap[r.data.strollerId].slug },
                                                        {},
                                                        `review-${r.data.id}`,
                                                    )}
                                                />)
                                            ) : (
                                                <ProductReview
                                                    key={`experience-${r.data.id}`}
                                                    className={styles.HomeRight__reviews__reviews__review}
                                                    image={
                                                        r.cover && (
                                                            <ImgCropped
                                                                photo={r.cover}
                                                                photoVersion={bestVersion(r.cover)}
                                                                width={64}
                                                                height={64}
                                                                mode={ImgCropMode.Embed}
                                                            />
                                                        )
                                                    }
                                                    title={
                                                        <Link
                                                            to={getWikiArticleUrl(
                                                                r.data.article.category.slug,
                                                                r.data.article.slug,
                                                            )}
                                                        >
                                                            {r.data.article.title}
                                                        </Link>
                                                    }
                                                    user={r.data.authorUser}
                                                    createdAt={r.data.created}
                                                    text={<Emojify size={17}>{r.body}</Emojify>}
                                                    photosCount={r.data.photos.length}
                                                    positivesCount={r.data.positives.length}
                                                    negativesCount={r.data.negatives.length}
                                                    score={r.data.starsCount}
                                                    to={getWikiArticleUrl(
                                                        r.data.article.category.slug,
                                                        r.data.article.slug,
                                                        {},
                                                        `experience-${r.data.id}`,
                                                    )}
                                                />
                                            ),
                                        )}
                                </div>

                                <div className={styles.HomeRight__reviews__more}>
                                    <Link to={getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG)}>
                                        {HOME_RIGHT_MORE_REVIEWS}
                                    </Link>
                                </div>
                            </Panel>
                        )}
                        {counsellings &&
                            counsellings.length > 0 &&
                            counsellings.map((counselling) => {
                                if (counselling.posts.length > 0) {
                                    return (
                                        <Counselling
                                            key={counselling.id}
                                            posts={counselling.posts}
                                            counselling={counselling.counselling}
                                            className={styles.HomeRight__counselling}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                    </>
                ) : null}
            </div>
        );
    }
}

function bestVersion(photo: PhotoEntity): string {
    if (photo.versions.includes('s1600x1600')) {
        return 's1600x1600';
    }

    if (photo.versions.includes('s742xn')) {
        return 's742xn';
    }

    return 's1600x1600';
}
