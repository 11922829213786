import cx from 'classnames';
import range from 'lodash-es/range';
import { CONTEST_POSTS_OLD_CONTESTS, CONTEST_POSTS_TITLE } from 'mk/autogenerated/translations/ContestPosts.fe43e4cc4dc4027e96738da7d672eb80'
import { contestsHomeUrl } from 'mk2/apps/contests/urls';
import { postDetailUrl, topicUrl } from 'mk2/apps/forum/helpers';
import { PostEntity, TopicEntity } from 'mk2/apps/forum/schemas';
import { Link } from 'mk2/components/Link';
import { LinkListItem, LinkListItemPlaceholder } from 'mk2/components/LinkListItem';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import React from 'react';
import styles from './ContestPosts.mscss';

interface OwnProps {
    className?: string;
    posts: PostEntity[];
}

type Props = OwnProps;

const ContestPosts: React.StatelessComponent<Props> = ({ className, posts }) => (
    <Panel className={cx(styles.ContestPosts, className)}>
        <PanelHeading
            title={CONTEST_POSTS_TITLE}
            url={contestsHomeUrl}
        />
        <ul className={styles.ContestPosts__list}>
            {posts.map((post) => (
                <LinkListItem
                    key={post.id}
                    to={postDetailUrl(post)}
                    title={(
                        <>
                            {post.heading}
                        </>
                    )}
                />
            ))}
        </ul>
        <Link className={styles.ContestPosts__more} to={contestsHomeUrl}>
            {CONTEST_POSTS_OLD_CONTESTS}
        </Link>
    </Panel>
);

ContestPosts.displayName = 'ContestPosts';

export default ContestPosts;

interface PlaceholderOwnProps {
    className?: string;
    length?: number;
}

export const ContestPostsPlaceholder: React.StatelessComponent<PlaceholderOwnProps> = ({ className, length = 3 }) => (
    <Panel className={cx(styles.ContestPosts, className)}>
        <PanelHeading title={CONTEST_POSTS_TITLE} />

        <ul className={styles.ContestPosts__list}>
            {range(length).map((idx) => (
                <LinkListItemPlaceholder key={idx} width={`${ [90, 85, 80, 70, 75, 85][idx % 6] }%`} />
            ))}
        </ul>
    </Panel>
);
