import cx from 'classnames';
import {
    HOME_STROLLERS_MAIN,
    STROLLER_STROLLEROPEDIA,
    STROLLERS_BUYING_GUIDES_HEADER,
} from 'mk/autogenerated/translations/Strollers.daaaaefff0d7d05eb32f2bf3e5887364'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { LinksBox } from 'mk2/apps/strollers/containers/Home/LinksBox';
import { FEATURES_LIST } from 'mk2/apps/strollers/features';
import { TypeFeature } from 'mk2/apps/strollers/features/options';
import { StrollerEntity, StrollerPhoto } from 'mk2/apps/strollers/schemas';
import { strollersDetailUrl, strollersUrl } from 'mk2/apps/strollers/urls';
import { getArticleBadges } from 'mk2/components/Badge';
import { Img } from 'mk2/components/Img';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import { cacheLast } from 'mk2/helpers/cache';
import { url } from 'mk2/helpers/urls';
import React from 'react';
import styles from './Strollers.mscss';

const IMG_HORSEY_WITH_MAGNIFIER = `${assetUrl}img/stickers/${ACTIVE_SERVER_ID}/40.png`;

interface OwnProps {
    className?: string;
    numStrollers: number;
    guideArticles: any[];
    strollers: StrollerEntity[];
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosMap: Record<number, StrollerPhoto>;
}

class StrollersComponent extends React.PureComponent<OwnProps> {
    private articleBadgesCache = cacheLast(true);

    public render() {
        const { className, guideArticles, numStrollers, strollers, strollersPhotosMap, strollersPreviewPhotoMap } = this.props;

        const articleBadges = this.articleBadgesCache(() => {
            const dict = {};
            for (const blogPostArticle of guideArticles) {
                dict[blogPostArticle.id] = getArticleBadges(blogPostArticle.hashtagItems).renderedBadges;
            }
            return dict;
        }, [guideArticles]);

        return (
            <Panel className={cx(styles.Strollers, className)}>
                <PanelHeading
                    title={STROLLER_STROLLEROPEDIA}
                    url={strollersUrl}
                />

                <div className={styles.Strollers__header}>
                    <Img
                        width={80}
                        height={57}
                        src={IMG_HORSEY_WITH_MAGNIFIER}
                        className={styles.Strollers__header__img}
                    />
                    <div className={styles.Strollers__caption__text}>
                        {HOME_STROLLERS_MAIN}
                    </div>
                </div>

                <LinksBox
                    hideSearch
                    pillsSubset={(FEATURES_LIST.type as TypeFeature).HP_ORDER}
                    showMorePill
                    noShadow
                />
                <div className={styles.Strollers__popular}>
                    {strollers.map((stroller, index) => {
                        const photo = stroller.id in strollersPreviewPhotoMap && strollersPreviewPhotoMap[stroller.id] in strollersPhotosMap ? strollersPhotosMap[strollersPreviewPhotoMap[stroller.id]] : null;
                        if (photo !== null) {
                            return (
                                <Link className={styles.Strollers__strollerImage} key={index}
                                      to={url(strollersDetailUrl, { slug: stroller.slug })}>
                                    <ImgCropped
                                        className={styles.Strollers__strollerImageImg}
                                        imgUrl={photo.url720x720}
                                        lazy={false}
                                        imgWidth={photo.originalWidth}
                                        imgHeight={photo.originalHeight}
                                        mode={ImgCropMode.Embed}
                                        width={69}
                                        height={69}
                                    />
                                </Link>
                            );
                        }
                    })}
                </div>
            </Panel>
        );
    }
}

export default StrollersComponent;

interface PlaceholderOwnProps {
    className?: string;
}

export const StrollersPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({
    className,
}) => (
    <Panel className={cx(styles.StrollersPlaceholder, className)}>
        <PanelHeading title={STROLLER_STROLLEROPEDIA} />

        <div className={styles.Strollers__header}>
            <Img
                width={80}
                height={57}
                src={IMG_HORSEY_WITH_MAGNIFIER}
                className={styles.Strollers__header__img}
            />
            {STROLLERS_BUYING_GUIDES_HEADER}
        </div>

        <LinksBox
            hideSearch
            pillsSubset={(FEATURES_LIST.type as TypeFeature).HP_ORDER}
            showMorePill
        />

        <LoadingPlaceholder width={250} height={15} />
        <LoadingPlaceholder width={250} height={15} />
        <LoadingPlaceholder width={250} height={15} />
    </Panel>
);

StrollersPlaceholder.displayName = 'StrollersPlaceholder';
