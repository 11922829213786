import cx from 'classnames';
import chunk from 'lodash-es/chunk';
import range from 'lodash-es/range';
import { HOMEPAGE_BLOG_PHOTOS, PHOTOS_SHOW_MORE } from 'mk/autogenerated/translations/Photos.9788687fddc91e5f4752cb99123a2cd9'
import { photoUrl } from 'mk/photo/photoUrl';
import { blogsPopularFeedUrl } from 'mk2/apps/blogs/urls';
import { HomePhotoblogPostEntity } from 'mk2/apps/home/schemas';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Panel } from 'mk2/components/Panel';
import { PanelFooter } from 'mk2/components/PanelFooter';
import { PanelHeading } from 'mk2/components/PanelHeading';
import React from 'react';
import styles from './Photos.mscss';

interface OwnProps {
    className?: string;
    posts: HomePhotoblogPostEntity[];
}

type Props = OwnProps;

const Photos: React.FunctionComponent<Props> = ({ className, posts }) => (
    <Panel className={cx(styles.Photos, className)}>
        <PanelHeading
            title={HOMEPAGE_BLOG_PHOTOS}
            url={blogsPopularFeedUrl}
        />

        <div className={styles.Photos__container}>
            {chunk(posts, 4).map((row, rowIndex) => (
                <div className={styles.Photos__row} key={rowIndex}>
                    {row.map((post, postIndex) => (
                        <Link key={postIndex} to={post.url}>
                            <Img className={styles.Photos__img} src={photoUrl(post.photo, 'c120x120')} width={73} height={73} />
                        </Link>
                    ))}
                </div>
            ))}
        </div>

        <PanelFooter title={PHOTOS_SHOW_MORE} url={blogsPopularFeedUrl} />
    </Panel>
);

Photos.displayName = 'Photos';

export default Photos;

interface PlaceholderOwnProps {
    className?: string;
}

export const PhotosPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className }) => (
    <Panel className={cx(styles.Photos, className)}>
        <PanelHeading
            title={HOMEPAGE_BLOG_PHOTOS}
            url={blogsPopularFeedUrl}
        />

        <div className={styles.Photos__container}>
            {range(3).map((row) => (
                <div className={styles.Photos__row} key={row}>
                    {range(4).map((post) => (
                        <LoadingPlaceholder key={post} width={73} height={73} />
                    ))}
                </div>
            ))}
        </div>

        <PanelFooter title={PHOTOS_SHOW_MORE} url={blogsPopularFeedUrl} />
    </Panel>
);
