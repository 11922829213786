// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const AUTHOR = "autor";
export const BADGE_ACCESSORIES = "P\u0159\u00edslu\u0161enstv\u00ed";
export const BADGE_EXPERT_TIPS = "Tipy ko\u010d\u00e1rkov\u00e9 poradkyn\u011b";
export const BADGE_HOWTO = "Jak na to";
export const BADGE_NEW = "Nov\u00e9";
export const BADGE_NEWS = "Novinky";
export const BADGE_OFFICIAL_PROFILE = "OFICI\u00c1LN\u00cd PROFIL";
export const BADGE_REDACTION_RECOMMENDATIONS = "Redak\u010dn\u00ed v\u00fdb\u011bry";
export const BADGE_VIDEO = "Video";
export const BRAND_AMBASSADOR = "AMBASADORKA";
export const INACTIVE_USER = "Neaktivn\u00ed";
export const REDACTION_REVIEW = "Redak\u010dn\u00ed recenze";
export const SHOPPING_GUIDE = "N\u00e1kupn\u00ed pr\u016fvodce";
export const TOPIC_MODERATOR = "Moder\u00e1tor";
export const VERIFIED_ORGANIZATION_BADGE = "Ov\u011b\u0159en\u00e1 organizace";
