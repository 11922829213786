import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { History } from 'history';
import { HOMEPAGE_SEARCH_IN_BAZAAR, HOMEPAGE_SEARCH_IN_BAZAAR_PLACEHOLDER } from 'mk/autogenerated/translations/BazaarSearch.e9767795861a1cf488026a545143ec2c'
import { bazaarUrl } from 'mk2/apps/bazaar/urls';
import { Form } from 'mk2/components/Form';
import { Panel } from 'mk2/components/Panel';
import { url } from 'mk2/helpers/urls';
import useEventCallback from 'mk2/hooks/useEventCallback';
import React, { useState } from 'react';
import styles from './BazaarSearch.mscss';

interface OwnProps {
    history?: History;
    className?: string;
}

type Props = OwnProps;

const BazaarSearch: React.FunctionComponent<Props> = ({ className, history }) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    const onSubmit = useEventCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const destination = url(bazaarUrl, {}, {
                q: value,
                sort: 'score_new',
            });

            if (history) {
                history.push(destination);
            } else {
                window.location.href = destination;
            }
        },
        [history, value],
    );

    const onChange = useEventCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            setValue(event.currentTarget.value);
        },
        [setValue],
    );

    const onFocus = useEventCallback(() => {
        setFocused(true);
    }, [setFocused]);

    const onBlur = useEventCallback(() => {
        setFocused(false);
    }, [setFocused]);

    return (
        <Form
            className={styles.BazaarSearch}
            action={bazaarUrl}
            method="get"
            onSubmit={onSubmit}
        >
            <Panel
                className={cx(styles.BazaarSearch__field, focused && styles['BazaarSearch__field--focused'])}
                tag="label"
            >
                <FontAwesomeIcon icon={faSearch} className={styles.BazaarSearch__field__icon} />
                <input type="hidden" name="sort" value="score_new" />
                <input
                    value={value}
                    type="text"
                    name="q"
                    placeholder={HOMEPAGE_SEARCH_IN_BAZAAR_PLACEHOLDER}
                    className={styles.BazaarSearch__field__input}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </Panel>
            <button className={styles.BazaarSearch__button} type="submit">
                {HOMEPAGE_SEARCH_IN_BAZAAR}
            </button>
        </Form>
    );
};

BazaarSearch.displayName = 'BazaarSearch';

export default React.memo<Props>(BazaarSearch);
