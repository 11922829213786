import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
    faLightbulbOn as fasLightbulbOn,
    faList as fasList,
    faRibbon as fasRibbon,
    faShoppingBag as fasShoppingBag,
    faShoppingBasket as fasShoppingBasket,
    faSparkles as fasSparkles,
    faStar as fasStar,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    AUTHOR,
    BADGE_ACCESSORIES,
    BADGE_EXPERT_TIPS,
    BADGE_HOWTO,
    BADGE_NEW,
    BADGE_NEWS, BADGE_OFFICIAL_PROFILE,
    BADGE_REDACTION_RECOMMENDATIONS,
    BADGE_VIDEO,
    BRAND_AMBASSADOR,
    INACTIVE_USER,
    REDACTION_REVIEW,
    SHOPPING_GUIDE,
    TOPIC_MODERATOR,
    VERIFIED_ORGANIZATION_BADGE,
} from 'mk/autogenerated/translations/Badge.fd4a7d8e8e3b12ae3dc9bb3a046203f6'
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { strollersArticlesOfSectionUrl } from 'mk2/apps/strollers/urls';
import { Link } from 'mk2/components/Link';
import { memoizeLRU } from 'mk2/helpers/cache';
import { url } from 'mk2/helpers/urls';
import { HashtagItemEntity } from 'mk2/schemas';
import React from 'react';
import styles from './Badge.mscss';

// define each BadgeType's style in styles
export enum BadgeType {
    OfficialProfile = 'OfficialProfile',
    Accessories = 'Accessories',
    Ambassador = 'Ambassador',
    Author = 'Author',
    HowTo = 'HowTo',
    InactiveUser = 'InactiveUser',
    Moderator = 'Moderator',
    New = 'New',
    News = 'News',
    TopGuides = 'TopGuides',
    RedactionReview = 'RedactionReview',
    BuyingGuides = 'BuyingGuides',
    VerifiedOrganization = 'VerifiedOrganization',
    Video = 'Video',
    ExpertTips = 'ExpertTips',
}

interface BadgeConfig {
    label: string;
    icon?: IconDefinition;
    sectionSlug?: string;
}

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

// keep synced with SECTION_HASHTAGS (photoblog.models)
export const BADGE_HASHTAGS: PartialRecord<keyof typeof BadgeType, string> = {
    BuyingGuides: {201: 'buying_guides', 202: 'buying_guides'}[ACTIVE_SERVER_ID],
    HowTo: {201: 'akonato', 202: 'jaknato'}[ACTIVE_SERVER_ID],
    Accessories: {201: 'prislusenstvo', 202: 'prislusenstvi'}[ACTIVE_SERVER_ID],
    New: {201: 'nove', 202: 'nove'}[ACTIVE_SERVER_ID],
    News: {201: 'novinky', 202: 'novinky'}[ACTIVE_SERVER_ID],
    RedactionReview: {201: 'redakcna_recenzia', 202: 'redakcni_recenze'}[ACTIVE_SERVER_ID],
    TopGuides: {201: 'top_guides', 202: 'top_guides'}[ACTIVE_SERVER_ID],
    Video: {201: 'video', 202: 'video'}[ACTIVE_SERVER_ID],
    ExpertTips: {201: 'tipy_poradkyne', 202: 'tipy_poradkyne'}[ACTIVE_SERVER_ID],
};

export const BADGE_CONFIGS: {
    [T in keyof typeof BadgeType]: BadgeConfig;
} = {
    OfficialProfile: {label: BADGE_OFFICIAL_PROFILE},
    Accessories: {label: BADGE_ACCESSORIES, icon: fasShoppingBag, sectionSlug: 'accessories'},
    Ambassador: {label: BRAND_AMBASSADOR},
    Author: {label: AUTHOR},
    HowTo: {label: BADGE_HOWTO, icon: fasList, sectionSlug: 'how-to'},
    InactiveUser: {label: INACTIVE_USER},
    Moderator: {label: TOPIC_MODERATOR},
    New: {label: BADGE_NEW},
    News: {label: BADGE_NEWS, icon: fasSparkles, sectionSlug: 'news'},
    TopGuides: {label: BADGE_REDACTION_RECOMMENDATIONS, icon: fasRibbon},
    RedactionReview: {label: REDACTION_REVIEW, icon: fasStar, sectionSlug: 'redaction-review'},
    BuyingGuides: {label: SHOPPING_GUIDE, icon: fasLightbulbOn, sectionSlug: 'buying-guides'},
    VerifiedOrganization: {label: VERIFIED_ORGANIZATION_BADGE},
    Video: {label: BADGE_VIDEO},
    ExpertTips: {label: BADGE_EXPERT_TIPS, icon: fasShoppingBasket, sectionSlug: 'expert-tips'},
};

interface OwnProps {
    className?: string;
    type: BadgeType;
}

type Props = OwnProps;

const Badge: React.FunctionComponent<Props> = ({ className, type}) => {
    return (
        <span className={cx(styles.Badge, styles[`Badge--${type}`], className)}>
            {BADGE_CONFIGS[type].icon ? <FontAwesomeIcon icon={BADGE_CONFIGS[type].icon} className={styles.Badge__icon} /> : null}
            {BADGE_CONFIGS[type].label}
        </span>
    );
};

Badge.displayName = 'Badge';

export default React.memo<Props>(Badge);

export const getArticleBadges = memoizeLRU(50, (hashtagItems: HashtagItemEntity[]) => {
    const hashtagLabels: string[] = [];
    for (const hashtagItem of hashtagItems) {
        hashtagLabels.push(hashtagItem.hashtag.label);
    }

    const badgeTypes: BadgeType[] = [];
    const renderedBadges: React.ReactNode[] = [];

    if (hashtagLabels.includes(BADGE_HASHTAGS.BuyingGuides)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.BuyingGuides.sectionSlug })}>
                <Badge type={BadgeType.BuyingGuides} />
            </Link>);
        badgeTypes.push(BadgeType.BuyingGuides);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.HowTo)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.HowTo.sectionSlug })}>
                <Badge type={BadgeType.HowTo} />
            </Link>);
        badgeTypes.push(BadgeType.HowTo);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.Accessories)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.Accessories.sectionSlug })}>
                <Badge type={BadgeType.Accessories} />
            </Link>);
        badgeTypes.push(BadgeType.Accessories);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.RedactionReview)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.RedactionReview.sectionSlug })}>
                <Badge type={BadgeType.RedactionReview} />
            </Link>);
        badgeTypes.push(BadgeType.RedactionReview);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.ExpertTips)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.ExpertTips.sectionSlug })}>
                <Badge type={BadgeType.ExpertTips} />
            </Link>);
        badgeTypes.push(BadgeType.ExpertTips);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.New)) {
        renderedBadges.push(<Badge type={BadgeType.New} />);
        badgeTypes.push(BadgeType.New);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.News)) {
        renderedBadges.push(
            <Link to={url(strollersArticlesOfSectionUrl, { sectionSlug: BADGE_CONFIGS.News.sectionSlug })}>
                <Badge type={BadgeType.News} />
            </Link>);
        badgeTypes.push(BadgeType.News);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.TopGuides)) {
        renderedBadges.push(<Badge type={BadgeType.TopGuides} />);
        badgeTypes.push(BadgeType.TopGuides);
    }
    if (hashtagLabels.includes(BADGE_HASHTAGS.Video)) {
        renderedBadges.push(<Badge type={BadgeType.Video} />);
        badgeTypes.push(BadgeType.Video);
    }
    return { renderedBadges, badgeTypes };
});
