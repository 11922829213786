import cx from 'classnames';
import { Link } from 'mk2/components/Link';
import React from 'react';
import styles from './PanelFooter.mscss';

interface OwnProps {
    className?: string;
    title: string | React.ReactNode;
    url?: string;

    onClick?(event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

export const PanelFooter: React.FunctionComponent<Props> = ({ className, title, url, onClick }) => {
    return url
        ? (
            <Link className={cx(styles.PanelFooter, className, styles['PanelFooter--link'])} to={url} onClick={onClick}>
                {title}
            </Link>
        ) : (
            <div className={cx(styles.PanelFooter, className)}>
                {title}
            </div>
        );
};

PanelFooter.displayName = 'PanelFooter';
