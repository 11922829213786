import { HOME_SHOW_MORE_POSTS } from 'mk/autogenerated/translations/Home.c882267d0779a13b02b40522334df58c'
import Bazaar, { BazaarPlaceholder } from 'mk2/apps/home/components/Bazaar';
import HomeBaseBannerComponent from 'mk2/apps/home/components/HomeBaseBannerComponent';
import Post, { PostPlaceholder } from 'mk2/apps/home/components/Post';
import { Props } from 'mk2/apps/home/containers/Home/HomePage';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Panel } from 'mk2/components/Panel';
import { TrackClick } from 'mk2/components/TrackClick';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { isLoading, LoadingState } from 'mk2/reducers/loadingState';
import React from 'react';
import styles from './Home.mscss';

class Home extends React.PureComponent<Props> {
    public render() {
        const { loadingState, homePosts } = this.props;

        return (
            <div className={styles.Home}>
                <LoadingSwitch
                    loadingState={loadingState}
                    onLoad={this.load}
                    onRenderSuccess={this.renderInitSuccess}
                    onRenderInit={this.renderInitSuccess}
                    hasData={homePosts && homePosts.length > 0}
                />
            </div>
        );
    }

    private renderInitSuccess = () => {
        const {
            isMobile,
            loadingState,
            nextPage,
            pageLoadingState,
            requestUser,
            history,
            bazaarCounts,
            place,
        } = this.props;

        return (
            <>
                {isLoading(loadingState) ? (
                    <BazaarPlaceholder className={styles.Home__bazaar} isMobile={isMobile} history={history} />
                ) : (
                    <Bazaar
                        className={styles.Home__bazaar}
                        history={history}
                        isMobile={isMobile}
                        prams={bazaarCounts.prams}
                        footwear={bazaarCounts.footwear}
                        toys={bazaarCounts.toys}
                        others={bazaarCounts.others}
                        places={bazaarCounts.places}
                        place={place}
                        requestUser={requestUser}
                    />
                )}

                {isMobile &&
                    <HomeBaseBannerComponent
                        className={styles.Home__subscribedPhotoblog}
                    />
                }

                <Panel className={styles.Home__posts}>
                    <>{this.renderPosts()}</>
                    {pageLoadingState === LoadingState.LOADING && (
                        <>
                            <PostPlaceholder className={styles.Home__post} />
                            <PostPlaceholder className={styles.Home__post} />
                            <PostPlaceholder className={styles.Home__post} />
                        </>
                    )}
                    <div className={styles.Home__pager}>
                        {pageLoadingState !== LoadingState.LOADING && nextPage ? (
                            <TrackClick
                                name="homepage_feed_click"
                                props={{ url: 'load_more', position: 0, page: nextPage }}
                            >
                                <Btn
                                    type={BtnType.BlueOutline}
                                    label={HOME_SHOW_MORE_POSTS}
                                    onClick={this.loadPage}
                                    data-cy="home-posts-load-more"
                                />
                            </TrackClick>
                        ) : (
                            // Needed for spacing in the flex
                            <div />
                        )}
                    </div>
                </Panel>
            </>
        );
    };

    private renderPosts = () => {
        const { homePosts, loadingState, isMobile } = this.props;
        return isLoading(loadingState)
            ? Array.from(Array(isMobile ? 6 : 30).keys()).map((index) => (
                  <PostPlaceholder key={index} className={styles.Home__post} />
              ))
            : homePosts.map((post, index) => (
                  <div key={post.id}>
                      <TrackClick
                          name="homepage_feed_click"
                          props={{
                              id: post.id,
                              url: post.url,
                              position: index + 1,
                          }}
                      >
                          <Post className={styles.Home__post} post={post} />
                      </TrackClick>
                  </div>
              ));
    };

    private load = () => {
        const { onLoad, location } = this.props;
        onLoad(location);
    };

    private loadPage = () => {
        if (this.props.nextPage && this.props.pageLoadingState !== LoadingState.LOADING) {
            this.props.onLoadPage(this.props.nextPage);
        }
    };
}

export default Home;
