import cx from 'classnames';
import range from 'lodash-es/range';
import { COUNSELLING_SHOW_MORE, TOPIC_COUNSELLOR } from 'mk/autogenerated/translations/Counselling.7c39c9a497368dfbfed4d7d9ac748f29'
import { counsellingsTopicUrl } from 'mk2/apps/counsellings/urls';
import { postDetailUrl, topicUrl } from 'mk2/apps/forum/helpers';
import { CounsellingEntity, PostEntity } from 'mk2/apps/forum/schemas';
import { forumUpdatedUrl } from 'mk2/apps/forum/urls';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { LinkListItem, LinkListItemPlaceholder } from 'mk2/components/LinkListItem';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Panel } from 'mk2/components/Panel';
import { PanelFooter } from 'mk2/components/PanelFooter';
import { PanelHeading, PanelHeadingPlaceholder } from 'mk2/components/PanelHeading';
import { url } from 'mk2/helpers/urls';
import React from 'react';
import styles from './Counselling.mscss';

interface OwnProps {
    className?: string;
    posts: PostEntity[];
    counselling: CounsellingEntity;
}

type Props = OwnProps;

const Counselling: React.FunctionComponent<Props> = ({ className, counselling, posts }) => {
    const counsellor = counselling?.counsellors?.find(c => c.isActive);

    return (
        <Panel className={cx(styles.Counselling, className)}>
            <PanelHeading title={posts[0].topic.name} url={topicUrl(posts[0].topic)} />
            <Link className={styles.Counselling__header} to={topicUrl(posts[0].topic)}>
                <ImgCropped
                    width={36}
                    height={36}
                    photo={counsellor.avatarPhoto}
                    photoVersion="s150x150"
                    className={styles.Counselling__header__avatar}
                    mode={ImgCropMode.Cover}
                />
                <div className={styles.Counselling__header__info}>
                    <div className={styles.Counselling__header__info__name}>{counsellor.name}</div>
                    <div className={styles.Counselling__header__info__badge}>{TOPIC_COUNSELLOR}</div>
                </div>
            </Link>
            <ul className={styles.Counselling__list}>
                {posts.map((post, index) => (
                    <LinkListItem key={post.id} to={postDetailUrl(post)} title={post.heading} />
                ))}
            </ul>
            <PanelFooter
                title={COUNSELLING_SHOW_MORE}
                url={url(counsellingsTopicUrl, { topicSlug: counselling.topic.slug })}
            />
        </Panel>
    );
};

Counselling.displayName = 'Counselling';

export default Counselling;

interface PlaceholderOwnProps {
    className?: string;
    length?: number;
}

export const CounsellingPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, length = 3 }) => (
    <Panel className={cx(styles.CounsellingPlaceholder, className)}>
        <PanelHeadingPlaceholder />
        <div className={styles.CounsellingPlaceholder__header}>
            <LoadingPlaceholder className={styles.Counselling__header__avatar} width={36} height={36} />
            <div className={styles.Counselling__header__info}>
                <div className={styles.Counselling__header__info__name}>
                    <LoadingPlaceholder width={200} height={15} />
                </div>
                <div className={styles.Counselling__header__info__badge}>{TOPIC_COUNSELLOR}</div>
            </div>
        </div>

        <ul className={styles.CounsellingPlaceholder__list}>
            {range(length).map((idx) => (
                <LinkListItemPlaceholder key={idx} width={`${[90, 85, 80, 70, 75, 85][idx % 6]}%`} />
            ))}
        </ul>
        <PanelFooter title={COUNSELLING_SHOW_MORE} url={forumUpdatedUrl} />
    </Panel>
);
