import cx from 'classnames';
import React from 'react';
import styles from './WidgetPlaceholder.mscss';

interface OwnProps {
    type: string;
}

type Props = OwnProps;

const WidgetPlaceholder: React.FunctionComponent<Props> = ({
type,
}) => (
    <div className={cx(styles.WidgetPlaceholder, styles[`WidgetPlaceholder--${type}`])}/>
);


WidgetPlaceholder.displayName = 'WidgetPlaceholder';

export default React.memo<Props>(WidgetPlaceholder);
