import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { FORUM_POSTS_SHOW_MORE } from 'mk/autogenerated/translations/BrandDaysPosts.7b8084cf4e4d273ad3a1ecd68af4e822'
import filesUrl from 'mk/common/filesUrl';
import { postDetailUrl } from 'mk2/apps/forum/helpers';
import { BrandDaysCampaignEntity, PostEntity } from 'mk2/apps/forum/schemas';
import { forumTopicUrl } from 'mk2/apps/forum/urls';
import { ImgVarWidth } from 'mk2/components/ImgVarWidth';
import { Link } from 'mk2/components/Link';
import { GrowPriority, LinkListItem, LinkListItemColor } from 'mk2/components/LinkListItem';
import { Panel } from 'mk2/components/Panel';
import { PanelFooter } from 'mk2/components/PanelFooter';
import { PanelHeading } from 'mk2/components/PanelHeading';
import { url } from 'mk2/helpers/urls';
import React from 'react';
import styles from './BrandDaysPosts.mscss';

interface OwnProps {
    className?: string;
    brandDaysCampaign: BrandDaysCampaignEntity;
    brandDaysPosts: PostEntity[];
}

type Props = OwnProps;

export class BrandDaysPosts extends React.PureComponent<Props> {
    public render() {
        const { brandDaysCampaign, brandDaysPosts, className } = this.props;
        return (
            <Panel className={cx(styles.BrandDaysPosts, className)}>
                <PanelHeading
                    title={brandDaysCampaign.title}
                    url={url(forumTopicUrl, {topicSlug: brandDaysCampaign.forumTopicSlug})}
                    logo={(
                        <Link
                            to={url(forumTopicUrl, {topicSlug: brandDaysCampaign.forumTopicSlug})}
                            className={styles.BrandDaysPosts__logo}
                        >
                            <ImgVarWidth
                                src={filesUrl + brandDaysCampaign.logoSrc}
                                imgWidth={brandDaysCampaign.logoWidth}
                                imgHeight={brandDaysCampaign.logoHeight}
                                height={24}
                                alt={brandDaysCampaign.title}
                            />
                        </Link>
                    )}
                />
                <ul className={styles.BrandDaysPosts__list}>
                    {brandDaysPosts.map((post) => (
                        <LinkListItem
                            key={post.id}
                            to={postDetailUrl(post)}
                            title={post.heading}
                            priority={GrowPriority.subtitle}
                            color={LinkListItemColor.Pink}
                        >
                            <FontAwesomeIcon icon={faCommentDots} /> {post.comments}
                        </LinkListItem>
                    ))}
                </ul>

                <PanelFooter
                    title={FORUM_POSTS_SHOW_MORE}
                    url={url(forumTopicUrl, {topicSlug: brandDaysCampaign.forumTopicSlug})}
                />
            </Panel>
        );
    }
}
