import {
    homepage_api_posts_url,
    homepage_api_url,
} from 'mk/urls/functions';
import {
    BrandDaysCampaignEntity,
    BrandDaysCampaignSchema,
    PostEntity,
    PostSchema,
    TopicEntity,
    TopicSchema,
} from 'mk2/apps/forum/schemas';
import {
    homeLoadApi,
    homeLoadPageApi,
    HomeLoadNormalizedResponse,
    HomeLoadPageNormalizedResponse,
    HomeLoadPageTriggerAction,
    HomeLoadTriggerAction,
    HOME_LOAD_PAGE_TRIGGER,
    HOME_LOAD_TRIGGER,
} from 'mk2/apps/home/containers/Home/Home.actions';
import { BazaarCounts } from 'mk2/apps/home/containers/Home/Home.reducers';
import {
    HomeCounsellingEntity,
    HomeCounsellingSchema,
    HomePhotoblogPostEntity,
    HomePhotoblogPostSchema,
    HomePostEntity,
    HomePostSchema,
} from 'mk2/apps/home/schemas';
import { Count } from 'mk2/components/NotificationsBadge';
import { handleXHRGetErrorSaga, normalizeError, XHRAction, XHRGetError } from 'mk2/helpers/api';
import { mergeDenormalized } from 'mk2/helpers/denormalized';
import { getLogger } from 'mk2/logger';
import { getRequestDeviceMobile } from 'mk2/reducers';
import { normalize } from 'normalizr';
import { parse } from 'query-string';
import React from 'react';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const logger = getLogger('home/Home.sagas');

interface ApiResponseHomeLoad {
    body: {
        forum: { posts: PostEntity[] };
        brandDays: { posts: PostEntity[]; campaign: BrandDaysCampaignEntity };
        contests: { posts: PostEntity[]; topic: TopicEntity };
        photoblog: { posts: HomePhotoblogPostEntity[] };
        homepage: { posts: HomePostEntity[]; nextPage: number | null };
        counselling: { counsellings: HomeCounsellingEntity[] };
        bazaar: { counts: BazaarCounts };
        lastVisitCountFriends: Count;
        place: {
            id: number;
            name: string;
        } | null;
        entities: HomeLoadNormalizedResponse['entities'];
        result: HomeLoadNormalizedResponse['result'];
    };
}

function* load({ currentUrl, xhr }: HomeLoadTriggerAction & XHRAction) {
    const isMobile = yield select(getRequestDeviceMobile);
    const query = parse(currentUrl.search);
    const brandDaysCampaign = query.with_brand_days_campaign as string;
    yield put(homeLoadApi.request(brandDaysCampaign));

    try {
        const response: ApiResponseHomeLoad = yield call(() =>
            xhr.get(homepage_api_url(), { mobile: isMobile, brandDays: brandDaysCampaign }),
        );

        const { entities, result, ...restResponse } = response.body;

        // TODO: Denormalize remaining entities on server
        const normalizedResponse: HomeLoadNormalizedResponse = normalize(restResponse, {
            forum: { posts: [PostSchema] },
            brandDays: { posts: [PostSchema], campaign: BrandDaysCampaignSchema },
            contests: { posts: [PostSchema], topic: TopicSchema },
            photoblog: { posts: [HomePhotoblogPostSchema] },
            homepage: { posts: [HomePostSchema] },
            counselling: { counsellings: [HomeCounsellingSchema] },
        });

        // TODO: Remove when all entities will be normalized on server
        const mergedResponse = mergeDenormalized(normalizedResponse, {
            entities,
            result,
        });

        yield put(homeLoadApi.success(mergedResponse));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(homeLoadApi.failure(normalizeError(error)));
    }
}

interface ApiResponseHomeLoadPage {
    body: {
        posts: HomePostEntity[];
        nextPage: number | null;
    };
}

function* loadPage({ xhr, page }: HomeLoadPageTriggerAction & XHRAction) {
    const isMobile = yield select(getRequestDeviceMobile);

    yield put(homeLoadPageApi.request(page));

    try {
        const response: ApiResponseHomeLoadPage = yield call(() =>
            xhr.get(homepage_api_posts_url(page), { mobile: isMobile }),
        );
        const normalizedResponse: HomeLoadPageNormalizedResponse = normalize(response.body, {
            posts: [HomePostSchema],
        });

        yield put(homeLoadPageApi.success(page, normalizedResponse));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(homeLoadPageApi.failure(page, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeLatest(HOME_LOAD_TRIGGER, load),
        takeLatest(HOME_LOAD_PAGE_TRIGGER, loadPage),
    ]);
}
