import cx from 'classnames';
import { PB_MENU_SUBSCRIBED_PHOTOBLOGS } from 'mk/autogenerated/translations/SubscribedPhotoblogs.90e0459864cd0ae33e345473cc1c3d42'
import { blogsFriendsFeedUrl } from 'mk2/apps/blogs/urls';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Count, NotificationsBadge } from 'mk2/components/NotificationsBadge';
import { Panel } from 'mk2/components/Panel';
import React from 'react';
import styles from './SubscribedPhotoblogs.mscss';

interface OwnProps {
    className?: string;
    lastVisitCountFriends: Count;
}

type Props = OwnProps;

const SubscribedPhotoblogs = React.memo<Props>(({
    className,
    lastVisitCountFriends,
}) => (
    <Panel className={cx(styles.SubscribedPhotoblogs, className)}>
        <Link
            className={styles.SubscribedPhotoblogs__link}
            to={blogsFriendsFeedUrl}
        >
            {PB_MENU_SUBSCRIBED_PHOTOBLOGS}
            <NotificationsBadge count={lastVisitCountFriends} />
        </Link>
    </Panel>
));

SubscribedPhotoblogs.displayName = 'SubscribedPhotoblogs';

export default SubscribedPhotoblogs;

interface PlaceholderOwnProps {
    className?: string;
}

export const SubscribedPhotoblogsPlaceholder = React.memo<PlaceholderOwnProps>(({
    className,
}) => (
    <Panel className={cx(styles.SubscribedPhotoblogs, className)}>
        <LoadingPlaceholder width="100%" height={16} />
    </Panel>
));
