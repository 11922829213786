import { faComments, faPencilAlt, faPlusCircle, faShoppingBasket } from '@fortawesome/pro-solid-svg-icons';
import { Location } from 'history';
import {
    ADD_FEED_POST,
    ADD_MENU_ADD_BLOG_POST,
    BZ2_ADD_PRODUCT,
    HOMEPAGE_ADD_BUTTON,
} from 'mk/autogenerated/translations/AddMenu.ed31e6cafccfa27ef4aaf4c96636ee73'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { analyticsStoreEvent } from 'mk2/actions';
import { bazaarCreateProductUrl } from 'mk2/apps/bazaar/urls';
import { blogsProfileRedirectUrl, blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { OTHER_CATEGORY_SLUG, UNCATEGORIZED_TOPIC_SLUG } from 'mk2/apps/forum/constants';
import { forumPostNewInTopicUrl } from 'mk2/apps/forum/urls';
import { loginUrl } from 'mk2/apps/users/urls';
import { MenuItem } from 'mk2/components/menu/MenuItem';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Menu } from 'mk2/containers/Menu/Menu';
import { menuToggle } from 'mk2/containers/Menu/Menu.actions';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import { AppState } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import { connect } from 'react-redux';

const MENU_ID = 'add-menu';

interface OwnProps {
    className?: string;
    requestUser: UserEntity;
    location: Location;
}
interface StateProps {}
interface DispatchProps {
    onToggleMenuMore();
    onStoreEvent(name: string, props: any);
}

export type Props = OwnProps & StateProps & DispatchProps;

export class AddMenu extends React.Component<Props> {
    public render() {
        const { onToggleMenuMore, className, requestUser, location } = this.props;

        return (
            <Menu
                menuId={MENU_ID}
                className={className}
                trigger={
                    <Btn
                        type={BtnType.Blue}
                        icon={faPlusCircle}
                        label={HOMEPAGE_ADD_BUTTON}
                        onClick={onToggleMenuMore}
                    />
                }
            >
                <MenuItem
                    label={ADD_MENU_ADD_BLOG_POST}
                    icon={faPencilAlt}
                    link={
                        isAuthenticated(requestUser)
                            ? url(blogsProfileUrl, { username: requestUser.username }) + '#pb-message-form'
                            : url(loginUrl, {}, { next: `${blogsProfileRedirectUrl}#pb-message-form` })
                    }
                    asAnchor
                />
                <MenuItem
                    label={BZ2_ADD_PRODUCT}
                    icon={faShoppingBasket}
                    link={bazaarCreateProductUrl}
                />
                <MenuItem
                    label={ADD_FEED_POST}
                    icon={faComments}
                    link={url(forumPostNewInTopicUrl, { categorySlug: OTHER_CATEGORY_SLUG, topicSlug: UNCATEGORIZED_TOPIC_SLUG })}
                />
            </Menu>
        );
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    return {};
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onToggleMenuMore: () => menuToggle(MENU_ID),
    onStoreEvent: analyticsStoreEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
