import { PRICE_CURRENCY_int, PRICE_CURRENCY_int__float, PRICE_FORFREE } from 'mk/autogenerated/translations/formatters.1f00a585fe0c97c92521cd6375190c03'
import { interpolate } from 'mk2/services/i18n';

export function formatThousands(numberToFormat: number | string, separator: string = ' ') {
    if (numberToFormat === undefined || numberToFormat === null) {
        return '';
    }

    return numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function formatPrice(price: string | number, displayZero: boolean = false, displayDecimals: boolean = true) {
    // we deal with strings here, because decimal values cannot be correctly represented in javascript
    if ((price === null) || (price === undefined)) {
        return '';
    }

    const priceFloat = typeof price === 'string' ? parseFloat(price) : price;
    if (!displayZero && priceFloat === 0) {
        return PRICE_FORFREE;
    }

    const parts = priceFloat.toFixed(2).split('.');
    if (parts[1] === '00' || !displayDecimals) {
        return interpolate(PRICE_CURRENCY_int, {int: parts[0]});
    } else {
        return interpolate(PRICE_CURRENCY_int__float, {int: parts[0], float: parts[1]});
    }
}

/**
 *  keep in sync with python code in common.utils.format_human_size
 *
 *  Format integer to human readale number
 *
 *      100      -> 100
 *      1000     -> 1k
 *      1500     -> 1.5k
 *      1520     -> 1520
 *      310000   -> 310k
 *      2000000  -> 2m
 *      3250000  -> 3.25m
 */
export function formatHumanSize(num: number): string {
    const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), 2);
    const units = {
        1: 'k',  // thousand
        2: 'm',  // million
    };

    for (let i = exponent; i >= 1; i--) {
        const humanNum = num / Math.pow(1000, i);
        const unit = units[i];
        // trim trailing zeros
        if (humanNum === Math.floor(humanNum)) {
            return `${Math.floor(humanNum)}${unit}`;
        } else if (humanNum === Math.round(humanNum * 10) / 10) {
            return `${Math.round(humanNum * 10) / 10}${unit}`;
        } else if (humanNum === Math.round(humanNum * 100) / 100 && i > 1) {
            // not too many digits after decimal point
            return `${Math.round(humanNum * 100) / 100}${unit}`;
        }
    }

    return `${num}`;
}
