// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const HOMEPAGE_POST_TYPE_ARTICLE = "\u010dl\u00e1nek";
export const HOMEPAGE_POST_TYPE_CONTEST = "Sout\u011b\u017e";
export const HOMEPAGE_POST_TYPE_COUNSELLING = "Z poradny";
export const HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC = "Poradna";
export const HOMEPAGE_POST_TYPE_DISCUSSION = "diskuze";
export const HOMEPAGE_POST_TYPE_EXPERIENCE = "zku\u0161enost";
export const HOMEPAGE_POST_TYPE_FORUM_TOPIC = "F\u00f3rum";
export const HOMEPAGE_POST_TYPE_FROM_INTERNET = "Z internetu:";
export const HOMEPAGE_POST_TYPE_PHOTOBLOG_ALBUM = "album";
export const HOMEPAGE_POST_TYPE_PHOTOBLOG_ARTICLE = "\u010dl\u00e1nek";
export const HOMEPAGE_POST_TYPE_REVIEW = "recenze";
export const HOMEPAGE_POST_TYPE_REVIEW_ARTICLE = "Z centra recenz\u00ed";
export const HOMEPAGE_POST_TYPE_SHORT_MESSAGE = "Kr\u00e1tk\u00e1 zpr\u00e1va";
export const HOMEPAGE_POST_TYPE_YOUTUBE_MK = "YouTube Modr\u00fd kon\u00edk";
