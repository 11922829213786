import { faComment, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    HOMEPAGE_POST_TYPE_ARTICLE,
    HOMEPAGE_POST_TYPE_CONTEST,
    HOMEPAGE_POST_TYPE_COUNSELLING,
    HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC,
    HOMEPAGE_POST_TYPE_DISCUSSION,
    HOMEPAGE_POST_TYPE_EXPERIENCE,
    HOMEPAGE_POST_TYPE_FORUM_TOPIC,
    HOMEPAGE_POST_TYPE_FROM_INTERNET,
    HOMEPAGE_POST_TYPE_PHOTOBLOG_ALBUM,
    HOMEPAGE_POST_TYPE_PHOTOBLOG_ARTICLE,
    HOMEPAGE_POST_TYPE_REVIEW,
    HOMEPAGE_POST_TYPE_REVIEW_ARTICLE,
    HOMEPAGE_POST_TYPE_SHORT_MESSAGE,
    HOMEPAGE_POST_TYPE_YOUTUBE_MK,
} from 'mk/autogenerated/translations/Post.1846ca2c24a147525aa47ec009c60bfb'
import { photoUrl } from 'mk/photo/photoUrl';
import { HomePostEntity, HomePostType } from 'mk2/apps/home/schemas';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { getDomain } from 'mk2/helpers/urls';
import React from 'react';
import styles from './Post.mscss';

interface HeadingOwnProps {
    post: HomePostEntity;
}

let PostHeading: React.FunctionComponent<HeadingOwnProps> = ({ post }) => (
    <>
        <div className={styles.Post__typeAndStats}>
            <div className={styles.Post__type}>
                {typeTranslations[post.type]}
                &nbsp;
                {post.type === HomePostType.FROM_INTERNET && <span>{getDomain(post.url)}</span>}
            </div>
            <div className={styles.Post__stats}>
                {post.likes > 0 && (
                    <span className={styles.Post__stat}>
                        {post.likes} <FontAwesomeIcon icon={faThumbsUp} />
                    </span>
                )}
                {post.comments > 0 && (
                    <span className={styles.Post__stat}>
                        {post.comments} <FontAwesomeIcon icon={faComment} />
                    </span>
                )}
            </div>
        </div>
        <h2 className={styles.Post__title}>
            {post.title}
            &nbsp;
            {post.type === HomePostType.FROM_INTERNET && <FontAwesomeIcon icon={faExternalLink} />}
        </h2>
    </>
);

PostHeading.displayName = 'PostHeading';

PostHeading = React.memo<HeadingOwnProps>(PostHeading);

interface OwnProps {
    className?: string;
    style?: React.CSSProperties;
    post: HomePostEntity;

    onClick?(event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

const typeTranslations = {
    [HomePostType.WIKI_ARTICLE]: HOMEPAGE_POST_TYPE_ARTICLE,
    [HomePostType.WIKI_REVIEW_ARTICLE]: HOMEPAGE_POST_TYPE_REVIEW_ARTICLE,
    [HomePostType.SHORT_MESSAGE]: HOMEPAGE_POST_TYPE_SHORT_MESSAGE,
    [HomePostType.DISCUSSION]: HOMEPAGE_POST_TYPE_DISCUSSION,
    [HomePostType.EXPERIENCE]: HOMEPAGE_POST_TYPE_EXPERIENCE,
    [HomePostType.REVIEW]: HOMEPAGE_POST_TYPE_REVIEW,
    [HomePostType.COUNSELLING]: HOMEPAGE_POST_TYPE_COUNSELLING,
    [HomePostType.COUNSELLING_TOPIC]: HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC,
    [HomePostType.FORUM_TOPIC]: HOMEPAGE_POST_TYPE_FORUM_TOPIC,
    [HomePostType.FROM_INTERNET]: HOMEPAGE_POST_TYPE_FROM_INTERNET,
    [HomePostType.PHOTOBLOG_ALBUM]: HOMEPAGE_POST_TYPE_PHOTOBLOG_ALBUM,
    [HomePostType.PHOTOBLOG_ARTICLE]: HOMEPAGE_POST_TYPE_PHOTOBLOG_ARTICLE,
    [HomePostType.CONTEST]: HOMEPAGE_POST_TYPE_CONTEST,
    [HomePostType.YOUTUBE_MK]: HOMEPAGE_POST_TYPE_YOUTUBE_MK,
};

const Post: React.FunctionComponent<Props> = ({ className, post, style }) => (
    <Link
        to={post.url}
        className={cx(styles.Post, className)}
        style={style}
        target={
            post.type === HomePostType.FROM_INTERNET || post.type === HomePostType.YOUTUBE_MK ? '_blank' : undefined
        }
        data-cy="home-post"
    >
        <div className={styles.Post__content}>
            <div className={styles.Post__image}>
                <Img
                    className={styles.Post__image__img}
                    src={photoUrl(post.photo, 'c300x300')}
                    width={80}
                    height={80}
                />
            </div>
            <div key={post.id} className={styles.Post__rightColumn}>
                <PostHeading post={post} />
            </div>
        </div>
    </Link>
);

Post.displayName = 'Post';

export default React.memo<Props>(Post);

// export default React.memo<Props>(Post);

interface PlaceholderOwnProps {
    className?: string;
    style?: React.CSSProperties;
}

let PostPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, style }) => (
    <div
        className={cx(styles.Post, className)}
        style={style}
        data-cy="home-post-placeholder"
    >
        <div className={styles.Post__content}>
            <div className={styles.Post__image}>
                <LoadingPlaceholder width={80} height={80} />
            </div>
            <div className={styles.Post__rightColumn}>
                <div className={styles.Post__type}>
                    <LoadingPlaceholder width={80} height={18} />
                </div>
                <span className={styles.Post__title}>
                    <LoadingPlaceholder width={200} height={18} />
                </span>
            </div>
        </div>
    </div>
);

PostPlaceholder.displayName = 'PostPlaceholder';

PostPlaceholder = React.memo<PlaceholderOwnProps>(PostPlaceholder);

export { PostPlaceholder };
