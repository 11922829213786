import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { HINT } from 'mk/autogenerated/translations/HomeBaseBanner.408cdb900a4f1f77b1b27d6ab4d63f0e'
import { Link } from 'mk2/components/Link';
import { Panel } from 'mk2/components/Panel';
import React from 'react';
import styles from './HomeBaseBanner.mscss';


interface OwnProps {
    className?: string;
    linkTo: string;
    icon?: IconDefinition;
    text: string;
    hintText?: string;
}

type Props = OwnProps;

const HomeBaseBanner = React.memo<Props>(({
    className, linkTo, icon, text, hintText,
}) => {
    return (
    <Panel className={cx(styles.HomeBaseBanner, className)}>
        <Link
            className={styles.HomeBaseBanner__link}
            to={linkTo}
        >
            <span className={styles.HomeBaseBanner__hint}>{hintText ? hintText : HINT}: </span>
            {icon && <FontAwesomeIcon icon={icon} />}
           {' '} {text}
        </Link>
    </Panel>
    );
});

HomeBaseBanner.displayName = 'HomeBaseBanner';

export default HomeBaseBanner;
