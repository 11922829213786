import { memoizeOne } from 'mk2/helpers/cache';
import React, { CSSProperties } from 'react';
import styles from './AdformInview.mscss';

declare const window: any;

interface OwnProps {
    placementId: number;
    passback: React.ReactNode;

    marginTopWithAd?: number;
    marginBottomWithAd?: number;
}

interface State {
    passbacked: boolean;
    expanded: boolean;
}

type Props = OwnProps;

export class AdformInview extends React.Component<Props, State> {

    public state: State = {
        passbacked: false,
        expanded: false,
    };

    private adformContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private dispose: () => void;

    private onInit: any[];
    private onEmpty: any[];

    private containerStyle = memoizeOne((marginTop: number, marginBottom: number): CSSProperties => (
        { marginTop, marginBottom }
    ));

    private outstreamStyle: CSSProperties = { height: '0', overflow: 'hidden' };

    public constructor(props) {
        super(props);

        const self: AdformInview = this; // tslint:disable-line
        this.onInit = [
            'on.init',
            function(settings) {
                this.on('collapse', onCollapse);
                this.on('expand', onExpand);

                if (self.dispose) {
                    self.dispose();
                }
                self.dispose = () => {
                    this.off('collapse', onCollapse);
                    this.off('expand', onExpand);
                };

                const collapseTimeout = settings.options.hideTime * 1000;

                function onCollapse() {
                    setTimeout(() => {
                        self.setState({ expanded: false });
                    }, collapseTimeout);
                }

                function onExpand() {
                    self.setState({ expanded: true });
                }
            },
        ];

        this.onEmpty = [`${this.props.placementId}.pmp.empty`, () => {
            this.setState({ passbacked: true });
        }];
    }

    public componentDidMount() {
        window._adform = window._adform || [];
        window._adform.push(this.onInit);
        window._adform.push(this.onEmpty);

        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('data-pmp-id', this.props.placementId.toString());
        scriptTag.setAttribute('data-close-oncomplete', 'false');
        scriptTag.setAttribute('data-init-on-inscreen', 'false');
        scriptTag.setAttribute('data-sound-onhover', 'false');
        scriptTag.src = 'https://s1.adform.net/banners/scripts/video/outstream/inview.js';
        this.adformContainer.current.appendChild(scriptTag);
    }

    public componentWillUnmount() {
        // Clear handlers
        window._adform = window._adform.filter((i) => i !== this.onInit && i !== this.onEmpty);
        if (this.dispose) {
            this.dispose();
        }
    }

    public render() {
        const { passback, marginTopWithAd, marginBottomWithAd } = this.props;
        const { passbacked, expanded } = this.state;

        return passbacked ? (
            passback
        ) : (
            <div
                className={styles.AdformInview}
                style={expanded ? this.containerStyle(marginTopWithAd, marginBottomWithAd) : undefined}
            >
                <div
                    id="adform-outstream"
                    className={styles.AdformInview__adform}
                    style={this.outstreamStyle}
                    ref={this.adformContainer}
                />
            </div>
        );
    }
}
