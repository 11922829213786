import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import range from 'lodash-es/range';
import {
    COMPETITION,
    FORUM_POSTS_SHOW_MORE,
    FORUM_WHERE_IS_DISCUSSED,
    HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC,
} from 'mk/autogenerated/translations/ForumPosts.4bdeac1ba7ae70cca14ea378cbb20042'
import { CONTESTS_TOPIC_ID } from 'mk2/apps/forum/constants';
import { postDetailUrl } from 'mk2/apps/forum/helpers';
import { PostEntity } from 'mk2/apps/forum/schemas';
import { forumUpdatedUrl } from 'mk2/apps/forum/urls';
import { isCounsellingPost } from 'mk2/apps/forum/utils';
import { GrowPriority, LinkListItem, LinkListItemPlaceholder } from 'mk2/components/LinkListItem';
import { Panel } from 'mk2/components/Panel';
import { PanelFooter } from 'mk2/components/PanelFooter';
import { PanelHeading } from 'mk2/components/PanelHeading';
import React from 'react';
import styles from './ForumPosts.mscss';

interface OwnProps {
    className?: string;
    posts: PostEntity[];
    heading: string;
}

type Props = OwnProps;

const ForumPosts: React.FunctionComponent<Props> = ({ className, posts, heading }) => {
    return (
        <Panel className={cx(styles.ForumPosts, className)}>
            <PanelHeading title={heading} url={forumUpdatedUrl} />
            <ul className={styles.ForumPosts__list}>
                {posts.map((post, index) => (
                    <LinkListItem
                        key={post.id}
                        to={postDetailUrl(post)}
                        title={
                            <>
                                {post.topic.id === CONTESTS_TOPIC_ID && (
                                    <span className={styles.ForumPosts__contest}>{COMPETITION}:</span>
                                )}
                                {isCounsellingPost(post) && (
                                    <span className={styles.ForumPosts__counselling}>
                                        {HOMEPAGE_POST_TYPE_COUNSELLING_TOPIC}:
                                    </span>
                                )}{' '}
                                {post.heading}
                            </>
                        }
                        priority={GrowPriority.subtitle}
                    >
                        <FontAwesomeIcon icon={faCommentDots} /> {post.comments}
                    </LinkListItem>
                ))}
            </ul>

            <PanelFooter title={FORUM_POSTS_SHOW_MORE} url={forumUpdatedUrl} />
        </Panel>
    );
};

ForumPosts.displayName = 'ForumPosts';

export default React.memo<Props>(ForumPosts);

interface PlaceholderOwnProps {
    className?: string;
    length?: number;
}

export const ForumPostsPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, length = 10 }) => (
    <Panel className={cx(styles.ForumPosts, className)}>
        <PanelHeading title={FORUM_WHERE_IS_DISCUSSED} url={forumUpdatedUrl} />

        <ul className={styles.ForumPosts__list}>
            {range(length).map((idx) => (
                <LinkListItemPlaceholder key={idx} width={`${[90, 85, 80, 70, 75, 85][idx % 6]}%`} />
            ))}
        </ul>

        <PanelFooter title={FORUM_POSTS_SHOW_MORE} url={forumUpdatedUrl} />
    </Panel>
);

ForumPostsPlaceholder.displayName = 'ForumPostsPlaceholder';
