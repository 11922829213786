import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { MORE, STROLLERS_SEARCH_HOMEPAGE_BTN_TITLE } from 'mk/autogenerated/translations/LinksBox.396ba704912a971b36a457feabe2cdbb'
import { StrollersBuyingGuides } from 'mk2/apps/strollers/components/StrollersBuyingGuides';
import { PageMode } from 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearch.constants';
import { FEATURES_LIST } from 'mk2/apps/strollers/features';
import { TypeFeature } from 'mk2/apps/strollers/features/options';
import { capitalizeFirstLetter, convertStrollerType } from 'mk2/apps/strollers/helpers';
import { strollersSearchByTypeUrl, strollersSearchUrl, strollersUrl } from 'mk2/apps/strollers/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Link } from 'mk2/components/Link';
import { url } from 'mk2/helpers/urls';
import { ContentItemEntity } from 'mk2/schemas';
import React from 'react';
import styles from './LinksBox.mscss';

interface OwnProps {
    className?: string;
    buyingGuide?: ContentItemEntity;
    hideSearch?: boolean;
    showFirstNLinks?: number;
    pillsSubset?: string[];
    showMorePill?: boolean;
    noShadow?: boolean;
}

type Props = OwnProps;

export const LinksBox: React.FunctionComponent<Props> = React.memo(({
    className,
    buyingGuide,
    hideSearch,
    pillsSubset,
    showMorePill,
    noShadow,
}) => {
    const strollerTypes = (FEATURES_LIST.type as TypeFeature);
    const pills = pillsSubset || strollerTypes.orderBeforeTrans(Object.keys(strollerTypes.OPTIONS));
    const shadowStyle = noShadow ? styles.LinksBox__noShadow : styles.LinksBox__boxShadow;
    return (
        <div className={cx(styles.LinksBox, shadowStyle, className)}>
            <div className={styles.LinksBox__categories}>
                <div className={styles.LinksBox__categories__categories}>
                    {pills.map((optionSlug) => (
                        <Link
                            key={optionSlug}
                            className={styles.LinksBox__categories__categories__category}
                            to={optionSlug === 'siblings'
                                ? url(
                                    strollersSearchUrl,
                                    {pageMode: PageMode.SHOW_SEARCH_RESULTS_FILTER_BY_QUERY},
                                    {number_of_kids: 'more'},
                                )
                                : url(strollersSearchByTypeUrl, {
                                    pageMode: PageMode.SHOW_SEARCH_RESULTS_FILTER_BY_TYPE,
                                    strollerType: convertStrollerType(optionSlug, '_to-'),
                                })
                            }
                        >
                            {capitalizeFirstLetter(strollerTypes.OPTIONS[optionSlug])}
                        </Link>
                    ))}
                    {showMorePill && (
                        <Link
                            key="more"
                            className={styles.LinksBox__categories__categories__category}
                            to={strollersUrl}
                        >
                            {`${capitalizeFirstLetter(MORE)}...`}
                        </Link>
                    )}
                </div>
            </div>

            {!hideSearch && (
                <div className={styles.LinksBox__search}>
                    <Btn
                        type={BtnType.Blue}
                        icon={faSearch}
                        link={url(strollersSearchUrl, { pageMode: PageMode.SHOW_SEARCH_FORM })}
                        label={STROLLERS_SEARCH_HOMEPAGE_BTN_TITLE}
                    />
                </div>
            )}

            {buyingGuide && (
                <StrollersBuyingGuides buyingGuideContentItem={buyingGuide} className={styles.LinksBox__buyingGuides} />
            )}
        </div>
    );
});

LinksBox.displayName = 'LinksBox';

interface PlaceholderProps {
    className?: string;
}

export const LinksBoxPlaceholder: React.FunctionComponent<PlaceholderProps> = ({ className }) => (
    // Display normal component but without buying guide
    // because we do not know how it will be displayed before loading it
    <LinksBox className={className} />
);

LinksBoxPlaceholder.displayName = 'LinksBoxPlaceholder';
