import { faImage, faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { READ_WHOLE_REVIEW } from 'mk/autogenerated/translations/ProductReview.fe861d1d5a2700ab2df412fa0bc27864'
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Clamp } from 'mk2/components/Clamp';
import { Dot } from 'mk2/components/Dot';
import { Emojify } from 'mk2/components/Emojify';
import { Link, OwnProps as LinkProps } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { StarsRating } from 'mk2/components/StarsRating';
import { timeAt } from 'mk2/helpers/timeformat';
import { url } from 'mk2/helpers/urls';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ProductReview.mscss';

interface OwnProps {
    className?: string;
    style?: React.CSSProperties;
    image?: React.ReactNode;
    title: React.ReactNode;
    user: UserEntity;
    createdAt: string;
    text: React.ReactNode;
    photosCount: number;
    positivesCount: number;
    negativesCount: number;
    score: number;
    to: LinkProps['to'];
}

type Props = OwnProps;

export const ProductReview: React.FunctionComponent<Props> = React.memo(({
    className,
    style,
    image,
    title,
    user,
    createdAt,
    text,
    photosCount,
    positivesCount,
    negativesCount,
    score,
    to,
}) => (
    <div className={cx(styles.ProductReview, className)} style={style}>
        {image && <div className={styles.ProductReview__image}>{image}</div>}
        <div className={styles.ProductReview__content}>
            <div className={styles.ProductReview__content__title}>{title}</div>
            <div className={styles.ProductReview__content__user}>
                <AvatarImg size={24} user={user} className={styles.ProductReview__content__user__avatar} />
                <Link
                    to={url(blogsProfileUrl, { username: user.username })}
                    className={styles.ProductReview__content__user__username}
                >
                    {user.username}
                </Link>

                <Dot spaces={1} className={styles.ProductReview__content__user__dot} />
                <span className={styles.ProductReview__content__user__createdAt}>{timeAt(createdAt)}</span>
            </div>

            {score > 0 && <div className={styles.ProductReview__content__score}>
                <StarsRating starsCount={score} canShowHalf />
            </div>}

            <div className={styles.ProductReview__content__text}>
                <Clamp clamp={3}>
                    <div className={styles.ProductReview__content__text__text}>
                        <Emojify size={17}>{text}</Emojify>
                    </div>
                </Clamp>
            </div>

            <div className={styles.ProductReview__content__badges}>
                {photosCount > 0 && (
                    <div className={styles.ProductReview__content__badges__photos}>
                        <FontAwesomeIcon icon={faImage} />
                        <span className={styles.ProductReview__content__badges__photos__count}>{photosCount}</span>
                    </div>
                )}
                {positivesCount > 0 && (
                    <div className={styles.ProductReview__content__badges__positives}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <span className={styles.ProductReview__content__badges__positives__count}>
                            {positivesCount}
                        </span>
                    </div>
                )}
                {negativesCount > 0 && (
                    <div className={styles.ProductReview__content__badges__negatives}>
                        <FontAwesomeIcon icon={faMinusCircle} />
                        <span className={styles.ProductReview__content__badges__negatives__count}>
                            {negativesCount}
                        </span>
                    </div>
                )}
                <Link className={styles.ProductReview__content__badges__readMore} to={to}>
                    {READ_WHOLE_REVIEW}
                </Link>
            </div>
        </div>
    </div>
));

ProductReview.displayName = 'ProductReview';

interface PlaceholderOwnProps {
    className?: string;
    style?: React.CSSProperties;
    image?: boolean;
}

export const ProductReviewPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, style, image }) => (
    <div className={cx(styles.ProductReview, className)} style={style}>
        {image && (
            <div className={styles.ProductReview__image}>
                <LoadingPlaceholder width={64} height={64} />
            </div>
        )}
        <div className={styles.ProductReview__content}>
            <div className={styles.ProductReview__content__title}>
                <LoadingPlaceholder width={124} />
            </div>
            <div className={styles.ProductReview__content__user}>
                <span className={styles.ProductReview__content__user__avatar}>
                    <LoadingPlaceholder width={24} height={24} />
                </span>
                <span className={styles.ProductReview__content__user__username}>
                    <LoadingPlaceholder width={53} />
                </span>
                <Dot spaces={1} className={styles.ProductReview__content__user__dot} />
                <span className={styles.ProductReview__content__user__createdAt}>
                    <LoadingPlaceholder width={73} />
                </span>
            </div>

            <div className={styles.ProductReview__content__score}>
                <LoadingPlaceholder width={88} height={14} />
            </div>

            <div className={styles.ProductReview__content__text}>
                <div className={styles.ProductReview__content__text__text}>
                    <LoadingPlaceholder width="100%" />
                    <LoadingPlaceholder width="100%" />
                    <LoadingPlaceholder width="65%" />
                </div>
            </div>

            <div className={styles.ProductReview__content__badges}>
                <div className={styles.ProductReview__content__badges__photos}>
                    <FontAwesomeIcon icon={faImage} />
                    <span className={styles.ProductReview__content__badges__photos__count}>
                        <LoadingPlaceholder width={8} />
                    </span>
                </div>
                <div className={styles.ProductReview__content__badges__positives}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span className={styles.ProductReview__content__badges__positives__count}>
                        <LoadingPlaceholder width={8} />
                    </span>
                </div>
                <div className={styles.ProductReview__content__badges__negatives}>
                    <FontAwesomeIcon icon={faMinusCircle} />
                    <span className={styles.ProductReview__content__badges__negatives__count}>
                        <LoadingPlaceholder width={8} />
                    </span>
                </div>
                <span className={styles.ProductReview__content__badges__readMore}>
                    <LoadingPlaceholder width={137} />
                </span>
            </div>
        </div>
    </div>
);

ProductReviewPlaceholder.displayName = 'ProductReviewPlaceholder';
