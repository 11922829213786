import { faLightbulbOn as fasLightbulbOn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    BUYING_GUIDES_SHOW_LESS,
    BUYING_GUIDES_SHOW_MORE_count,
    STROLLERS_BUYING_GUIDES_HEADER,
} from 'mk/autogenerated/translations/StrollersBuyingGuides.cc4c34d8e2e70b6939bb92c2bd69469f'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import { astToHtml, defaultFilterAdditionalData } from 'mk2/helpers/article_ast/filters';
import { getAllDescendantsOfAType } from 'mk2/helpers/article_ast/utils';
import useEventCallback from 'mk2/hooks/useEventCallback';
import { ContentItemEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React, { useState } from 'react';
import styles from './StrollersBuyingGuides.mscss';

const IMG_HORSEY_WITH_MAGNIFIER = `${assetUrl}img/stickers/${ACTIVE_SERVER_ID}/40.png`;

interface OwnProps {
    className?: string;
    buyingGuideContentItem: ContentItemEntity;
}

type Props = OwnProps;

export const StrollersBuyingGuides: React.FunctionComponent<Props> = React.memo(({
    className,
    buyingGuideContentItem,
}) => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const toggleShowAll = useEventCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            setShowAll((s) => !s);
        },
        [setShowAll],
    );

    const buyingGuideLinkNodes = getAllDescendantsOfAType(buyingGuideContentItem.contentAST, 'link');
    if (!buyingGuideLinkNodes.length) {
        return null;
    }
    const showToggleBtn = buyingGuideLinkNodes.length > 3;

    const visibleBuyingGuideLinkNodes = showAll || !showToggleBtn
        ? buyingGuideLinkNodes
        : buyingGuideLinkNodes.slice(0, 3);

    const linkedObjects = buyingGuideContentItem.contentASTAdditionalData.linkedObjects;
    return (
        <div className={cx(styles.StrollersBuyingGuides, className)}>
            <div className={styles.StrollersBuyingGuides__header}>
                <Img
                    width={80}
                    height={57}
                    src={IMG_HORSEY_WITH_MAGNIFIER}
                    className={styles.StrollersBuyingGuides__header__img}
                />
                {STROLLERS_BUYING_GUIDES_HEADER}
            </div>
            {visibleBuyingGuideLinkNodes.map((buyingGuideLinkNode, i) => (
                <div className={styles.StrollersBuyingGuides__item} key={`bg-${i}`}>
                    <div className={styles.StrollersBuyingGuides__item__icon}>
                        <FontAwesomeIcon icon={fasLightbulbOn} />
                    </div>
                    <div className={styles.StrollersBuyingGuides__item__text}>
                        {astToHtml(buyingGuideLinkNode, {
                            ...defaultFilterAdditionalData,
                            linkedObjects,
                            rootDiv: false,
                            renderSpecialWikiLinks: false,
                        })}
                    </div>
                </div>
            ))}
            {showToggleBtn && (
                <Btn
                    label={showAll
                        ? BUYING_GUIDES_SHOW_LESS
                        : interpolate(BUYING_GUIDES_SHOW_MORE_count, {
                            count: buyingGuideLinkNodes.length - visibleBuyingGuideLinkNodes.length,
                        })}
                    type={BtnType.Link}
                    onClick={toggleShowAll}
                />
            )}
        </div>
    );
});

StrollersBuyingGuides.displayName = 'StrollersBuyingGuides';
