import uniq from 'lodash-es/uniq';
import {
    HomeLoadFailureAction,
    HomeLoadPageFailureAction,
    HomeLoadPageRequestAction,
    HomeLoadPageSuccessAction,
    HomeLoadRequestAction,
    HomeLoadSuccessAction,
    HOME_LOAD_FAILURE,
    HOME_LOAD_PAGE_FAILURE,
    HOME_LOAD_PAGE_REQUEST,
    HOME_LOAD_PAGE_SUCCESS,
    HOME_LOAD_REQUEST,
    HOME_LOAD_SUCCESS,
} from 'mk2/apps/home/containers/Home/Home.actions';
import { Count } from 'mk2/components/NotificationsBadge';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface StrollerBrandOption {
    name: string;
    slug: string;
}

export interface StrollerOption {
    name: string;
    slug: string;
}

export interface StrollerOptions {
    [brandSlug: string]: StrollerOption[];
}

export interface BazaarCounts {
    prams: number;
    footwear: number;
    toys: number;
    others: number;
    locations: Record<number, number>;
    places: Record<number, number>;
}

export interface HomePageState {
    homePostIds: number[];
    forumPostsIds: number[];
    brandDaysCampaignId: number;
    brandDaysPostsIds: number[];
    contestPostsIds: number[];
    homeCounsellingIds: number[];
    numStrollers: number;
    photoblogPostsIds: number[];
    loadingState: LoadingState;
    errorMessage: string;
    nextPage: number | null;
    pageLoadingState: LoadingState;
    pageErrorMessage: string;
    lastVisitCountFriends: Count | '' | null;
    bazaarCounts: BazaarCounts;
    place: {
        id: number;
        name: string;
        countyId: number;
    } | null;
    strollerArticlesIds: number[];
    strollerReviewsIds: number[];
    reviewsStrollersIds: number[];
    wikiExperiencesIds: number[];
    strollerIds: number[];
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosIds: number[];
}

export const initialHomePageState: HomePageState = {
    homePostIds: [],
    forumPostsIds: [],
    brandDaysCampaignId: null,
    brandDaysPostsIds: [],
    contestPostsIds: [],
    homeCounsellingIds: [],
    numStrollers: 0,
    photoblogPostsIds: [],
    loadingState: LoadingState.INIT,
    errorMessage: null,
    pageLoadingState: LoadingState.INIT,
    pageErrorMessage: null,
    nextPage: 0,
    lastVisitCountFriends: null,
    bazaarCounts: {
        prams: 0,
        toys: 0,
        footwear: 0,
        others: 0,
        locations: {},
        places: {},
    },
    place: null,
    strollerArticlesIds: [],
    strollerReviewsIds: [],
    reviewsStrollersIds: [],
    wikiExperiencesIds: [],
    strollerIds: [],
    strollersPreviewPhotoMap: {},
    strollersPhotosIds: [],
};

declare type HomePageReducerAction =
    HomeLoadRequestAction
    | HomeLoadSuccessAction
    | HomeLoadFailureAction
    | HomeLoadPageRequestAction
    | HomeLoadPageSuccessAction
    | HomeLoadPageFailureAction;

export const homePageReducer: Reducer<HomePageState> =
    (state = initialHomePageState, action: HomePageReducerAction) => {
    switch (action.type) {
        case HOME_LOAD_REQUEST:
        case HOME_LOAD_FAILURE: {
            return tupdate(state, {
                loadingState: loadingState(undefined, action),
                errorMessage: errorMessage(undefined, action),
            });
        }

        case HOME_LOAD_SUCCESS: {
            return tupdate(state, {
                loadingState: loadingState(undefined, action),
                errorMessage: errorMessage(undefined, action),
                forumPostsIds: action.response.result.forum.posts || state.forumPostsIds,
                contestPostsIds: action.response.result.contests.posts || state.contestPostsIds,
                homeCounsellingIds: action.response.result.counselling.counsellings || state.homeCounsellingIds,
                numStrollers: action.response.result.strollerbase.numStrollers || state.numStrollers,
                photoblogPostsIds: action.response.result.photoblog.posts || state.photoblogPostsIds,
                homePostIds: action.response.result.homepage.posts || state.homePostIds,
                brandDaysCampaignId: action.response.result.brandDays.campaign || state.brandDaysCampaignId,
                brandDaysPostsIds: action.response.result.brandDays.posts || state.brandDaysPostsIds,
                nextPage: action.response.result.homepage.nextPage,
                bazaarCounts: action.response.result.bazaar.counts || state.bazaarCounts,
                lastVisitCountFriends: action.response.result.lastVisitCountFriends,
                place: action.response.result.place || state.place,
                strollerArticlesIds: action.response.result.strollerArticles ?? [],
                strollerReviewsIds: action.response.result.strollerReviews ?? [],
                reviewsStrollersIds: action.response.result.reviewsStrollers ?? [],
                wikiExperiencesIds: action.response.result.wikiExperiences ?? [],
                strollerIds: action.response.result.strollers ?? [],
                strollersPreviewPhotoMap: tupdate(state.strollersPreviewPhotoMap, action.response.result.strollersPreviewPhotoMap) ?? [],
                strollersPhotosIds: uniq([
                    ...(state.strollersPhotosIds || []),
                    ...(action.response.result.strollersPhotos || []),
                ]),
            });
        }

        case HOME_LOAD_PAGE_REQUEST:
        case HOME_LOAD_PAGE_FAILURE: {
            return tupdate(state, {
                pageLoadingState: loadingState(undefined, action),
                pageErrorMessage: errorMessage(undefined, action),
            });
        }

        case HOME_LOAD_PAGE_SUCCESS: {
            return tupdate(state, {
                pageLoadingState: loadingState(undefined, action),
                pageErrorMessage: errorMessage(undefined, action),
                homePostIds: uniq([ ...state.homePostIds, ...action.response.result.posts ]),
                nextPage: action.response.result.nextPage,
            });
        }

        default:
            return state;
    }
};
