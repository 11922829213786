import { ACTIVE_SERVER_ID } from 'mk/settings';
import { StrollerPhoto } from 'mk2/schemas';

const CDN = `https://mkimgstrollers${ACTIVE_SERVER_ID === 201 ? 'sk' : 'cz'}.vshcdn.net`;

export const dummyPhoto: StrollerPhoto = {
    id: 0,
    url100x100: `${CDN}/spsp/100x100/OZ4GHQYqGs7.png`,
    originalHeight: 324,
    originalWidth: 320,
    url250x250: `${CDN}/spsp/250x250/OZ4GHQYqGs7.png`,
    url720x720: `${CDN}/spsp/720x720/OZ4GHQYqGs7.png`,
};

export const strollerSingleVariant = (stroller): boolean => {
    const max = 1;

    for (const key in stroller.configurables) {
        if (Object.hasOwnProperty.call(stroller.configurables, key)) {
            const length = Object.keys(stroller.configurables[key]).length;
            if (length > max) {
                return false;
            }
        }
    }
    return true;
};
